.OrderDataBlock {
  background: white;
  padding: 1em;
}

.CustomerSatisfection {
  background: white;
  height: 100%;
}
.HomeWidhthTitleBar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #bfd2fd;
  padding-bottom: 5px;
}
.OrderWidthBlock {
  display: flex;
  justify-content: space-between;
}
.OrderWidhthSingalBlock {
  background: #f5f8ff;
  border-radius: 5px;
  margin: 5px 0 0 10px;
  margin-bottom: 0px !important;
  flex: 1;
  flex-wrap: wrap;
}
.PlannedBlock {
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  align-items: baseline;
}
.fontSizeTotalOrder {
  font-size: 12px;
  font-weight: 500;
}
.OrdersNumber {
  /* color: #2C63E5; */
  font-weight: bold;
}
.OrderParcentge {
  font-size: 17px;
  color: #00c28b;
}
.CircleContaint {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
}
.InnerCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #00c28b;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.OutrerCircle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #00c28b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.LightCircle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  border: 4px solid #c2faea;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.TransportationWidhthBlock {
  background: white;
  padding: 1em;
}
.TransportInnercircle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #bfd2fd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.TransportOuterCircle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 4px solid #2c63e5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  margin: auto;
}
.TransportCircleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 25px;
}
.numberRed {
  color: red;
  margin: 10px;
}
.numbergreen {
  color: #264ca4;
  margin: 10px;
}
.StockOut {
  display: flex;
  align-items: center;
  justify-content: center;
}
.OtifBtn {
  padding: 4px 55px;
  background: #2c63e5;
  border: none;
  outline: none;
  color: #fff;
  font-size: 12px;
  border-radius: 4px 0px 0px 4px;
}
.OntimeBtn {
  padding: 3px 40px;
  border: none;
  outline: none;
  color: #000;
  border: 1px solid #2c63e5;
  font-size: 12px;
  background: transparent;
  border-radius: 0px 4px 4px 0px;
}
.btnOtifOntimeContanet {
  display: flex;
  align-items: center;
  justify-content: center;
}
.averageInvestryContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
.investryBox {
  background: #f5f8ff;
  margin: 10px;
  border-radius: 5px;
  padding: 10px 60px 12px 20px;
}
.AverageBox {
  background: #f5f8ff;
  margin: 10px;
  border-radius: 5px;
  padding: 10px 25px 12px 20px;
}

.HrsBoxContaint {
  display: flex;
  align-items: center;
  justify-content: center;
}
.HrsFirst {
  background: #00c28b;
  padding: 5px 50px;
  border-radius: 4px 1px 1px 4px;
}
.HrsSecond {
  background: #f2984a;
  padding: 5px 75px;
  border-radius: 0px 4px 4px 0px;
}
.HrsTextFont {
  font-size: 18px;
  font-weight: bold;
}
.spanHrsFont {
  font-size: 12px;
  font-weight: bold;
}
.HrsText {
  display: flex;
  justify-content: space-between;
  margin: 12px 40px 0px 40px;
}
.ThreeCircleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.treeeCircleTeaxtSize {
  font-size: 12px;
}
.CostFinanceInnerContainer {
  background: #f5f8ff;
}
.LogisticCostContainer {
  background: #f5f8ff;
  border-radius: 10px;
}
.OverAllBox {
  display: flex;
  margin-left: 25px;
}
.FontOverAllBox {
  font-size: 12px;
}
.FontSizeTwintin {
  font-size: 13px;
}

.OverAllBoxSecond {
  margin: 6px 70px 3px -15px;
}
.BorderBottom {
  font-size: 11px;
  border-bottom: 1px solid #bfd2fd;
  padding-bottom: 5px;
  margin: 2px 17px 0px -16px !important;
}
.AvergeCustomerBoxContainer {
  display: flex;
}
.AvgCustomerFirst {
  background: #f5f8ff;
  padding: 10px 70px 23px 10px;
  border-radius: 10px;
}
.AvgCustomersecond {
  background: #f5f8ff;
  padding: 10px 105px 23px 10px;
  border-radius: 10px;
}
.TextWeighth {
  font-size: 24px;
  font-weight: bold;
}
.water-flow-chart {
  display: flex;
  background-image: url(../Images/waterflow.svg);
  height: 7em;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
/*========================plannig box csss=================================*/
.PlannigBoxContainer {
  display: flex;
  justify-content: space-between;
}
.PlannigSingaleBox {
  margin: 10px;
  flex: 1;
  border: 1px solid #bfd2fd;
  padding: 20px 20px 20px 20px;
}
.SingaleBoxCircle {
  display: flex;
  align-items: center;
  justify-content: center;
}
