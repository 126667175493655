.action-break{
border-left: 2px solid lightgrey !important;
}
.my-task-action-btn{
    font-size: 12px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}
.enabled{
background-color: #2C63E5 !important;
color: white !important;
}
.enabled-icon-edit{
color: #2C63E5 !important;
}
.enabled-icon-trash{
color: #FF5757 !important;
}
.action-btn-icon{
        font-size: 15px !important;
    color: white;
    padding: 2px 10px;
    border-left: 2px solid white !important;
    border-radius:0 5px 5px 0;

}
.action-btn-btn{
        padding: 2px 30px 2px 10px;
        border-radius: 5px 0 0 5px;
}

.status-btn:hover{
    color: white !important;
}
.delete-modal-btn{
    float: right;
}
.delete-modal-header{
    margin-bottom: 10px !important;
}
.delete-modal-paragraph{
    margin-bottom: 10px !important;
}
.modal-btn-cancel{
    background: none !important;
    border: 1px solid #70707038;
}
.modal-btn-delete{
    background: #FF5757;
    border: 1px solid #FF5757;
}