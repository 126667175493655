.SidebarContentBlock{
  flex-grow: 0;
  margin: auto 15px;
}

.BlockContents{
  background-color: #F5F8FF;
  border-radius: 5px;
  padding: 15px 8px;
}
.BlockContents h4{
  margin-bottom: 1em !important;
  font-size: 15px !important;
  color: #1A1A1A !important;
  font-weight: 700;
}
.BlockContents p{
  font-size: 14px;  
  color: #666666;
}
.BlockContents button{
  font-size: 10px !important;
    margin-top: 1.5em ;
    background: white;
    font-weight: 700;
}