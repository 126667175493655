.addtask-success-popup{
    display: flex;
    align-items: center;
    background: #008F66;
    color: white;
    box-shadow: 0px 12px 64px #1a1a1a3d;
    border-radius: 4px;
    padding: 10px 65px 10px 10px;
    width: fit-content;
}
.Progress-bar{
    width: 13.5em;
    padding: 10px;
    background-color: lightgrey;
    border-radius: 4px;
     position: relative;
  animation-name: example;
  animation-duration: 4s;
  float: right;
}

@keyframes example {
  0%   {right:-250px; bottom:0px;}

  100% {right:0px; bottom:0px;}
}