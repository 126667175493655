.main-table-container{
    margin: 2em 1em;
    background: white;
    box-shadow: 0px 24px 40px #1a1a1a29;
    border-radius: 4px;
}
.table-header-block{
    display: flex;
    padding: 10px 15px;
    align-items: center;
    border-bottom: 1px solid lightgrey;
}
.notification-count{
    border-radius: 50%;
    border: 1px solid;
    font-size: 10px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    background: white;
    
}
.active-btn-count{
    color: #2C63E5;
}
.status-btn{
    padding: 3px 10px;
    font-size: 12px;
    border-radius: 20px;
    margin: 0 5px;
    width: 115px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}
.search-box{
    display: flex;
    align-items: center;
    font-size: 12px;
    border: 1px solid lightgrey;
    width: 200px;
    padding: 5px;
    border-radius: 5px;
}


.DataTable{
text-transform: capitalize;
}
.DataTable th,td{
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid;
    border: none;
}
.DataTable th{
    font-weight: 600;
    font-size: 12px;    
}
.DataTable tr{
    vertical-align: middle;
}
.table-header-block p{
    font-size: 11px;
}
.tableHeading{
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #1A1A1A !important;

}
#table-row-select{
    border: 1px solid lightgrey;
    padding: 2px 5px;
    font-size: 12px;
}
.page-num{
    padding: 5px 10px;
    border-radius: 6px;
}
.active{
     background: #2C63E5;
     color: white;
}
.count-row-perpage{
    padding: 0 5px 0 50px;
}
.icon-corner{
    display: flex;
    align-items: center;
}
