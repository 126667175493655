
  .logo_container{
      background: #F5F8FF;
      border-top: 1px solid #BFD2FD;
      border-bottom: 1px solid #BFD2FD;
      margin-top: 1.2em;           
      margin-bottom: 0.9em;    
        
  }
  .pro_contantsty{
    width: 100%;
    padding: 5px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .btn_container{
      display: flex;
      justify-content: flex-end;
  }
  .roted_icon{
      
    transform: rotate(90deg);
  }
  .filter_sty{
      border: 1px solid #2C63E5;
      border-radius: 5px;
      background: #fff;
      color: #2C63E5;
      padding: 2px 35px;
  }
  .add_btn_sty{
    
    border-radius: 5px;
    background: #2C63E5;
    color: #fff;
    padding: 3px 20px;
  }
  .breadcum-page-heading{
    font-size: 20px;
    font-weight: 600;
    color: #1A1A1A;
  }
  .breadcum-page-sub-heading{
    font-size: 14px;
    color: #666666;
    margin-bottom: 5px !important;    
  }