p {
  margin: 0px !important;
}
a {
  text-decoration: none !important;
}
body {
  font-family: "Poppins" !important;
}

button:focus {
  box-shadow: none !important;
}

.makeStyles-drawerClose-5 ~ .MainContent .logo_container {
  margin-left: 55px !important;
}

/*///////////////////customer slider css start///////////////////////////////////////////////////*/
.loading {
  width: 100px;
  height: 100px;
  /* margin: 30px auto; */
  position: relative;
}

.outer-shadow,
.inner-shadow {
  z-index: 4;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5); */
}

.inner-shadow {
  top: 50%;
  left: 50%;
  width: 85px;
  height: 85px;
  margin-left: -42px;
  margin-top: -42px;
  border-radius: 100%;
  background-color: #ffffff;
}
.hold {
  position: absolute;
  width: 100%;
  height: 100%;
  clip: rect(0px, 100px, 100px, 50px);
  border-radius: 100%;
  background-color: #c2faea;
}

.fill,
.dot span {
  background-color: #00c28b;
}

.fill {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  clip: rect(0px, 50px, 100px, 0px);
}

.left .fill {
  transform: rotate(180deg);
  z-index: 1;
  -webkit-animation: left 1s linear;
  -moz-animation: left 1s linear;
  animation: left 1s linear both;
}

.right {
  background-color: "red";
  z-index: 3;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.right .fill {
  transform: rotate(90deg);
  z-index: 3;
  -webkit-animation: right 1s linear;
  -moz-animation: right 1s linear;
  animation: right 1s linear both;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.inner-shadow img {
  margin-left: 17px;
  margin-top: 15px;
}

.CouunPercent {
  position: absolute;
  z-index: 5;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CouunPercent p {
  background: #00c28b;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*///////////////////customer slider css end///////////////////////////////////////////////////*/
/*///////////////////tarnsportation slider css start///////////////////////////////////////////////////*/

.loadingTransport {
  width: 100px;
  height: 100px;
  /* margin: 30px auto; */
  position: relative;
}

.outer-shadows,
.inner-shadows {
  z-index: 4;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5); */
}

.inner-shadows {
  top: 50%;
  left: 50%;
  width: 75px;
  height: 75px;
  margin-left: -43px;
  margin-top: -43px;
  border-radius: 100%;
  background-color: #f5f8ff;
}
.holds {
  position: absolute;
  width: 90%;
  height: 90%;
  clip: rect(0px, 100px, 100px, 45px);
  border-radius: 100%;
  background-color: #f5f8ff;
}

.fills,
.dots span {
  background-color: #2c63e5;
}

.fills {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  clip: rect(0px, 50px, 100px, 0px);
}

.lefts .fills {
  transform: rotate(180deg);
  z-index: 1;
  -webkit-animation: left 1s linear;
  -moz-animation: left 1s linear;
  animation: left 1s linear both;
}

.rights {
  background-color: "red";
  z-index: 3;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rights .fills {
  transform: rotate(90deg);
  z-index: 3;
  -webkit-animation: right 1s linear;
  -moz-animation: right 1s linear;
  animation: right 1s linear both;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.inner-shadows img {
  margin-left: 17px;
  margin-top: 15px;
}

.CouunPercents {
  position: absolute;
  z-index: 5;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CouunPercents p {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

/*///////////////////customer slider css end///////////////////////////////////////////////////*/

/*************************************Theme Css Start*******************************************/
.poppins-32px-700 {
  font-size: 32px !important;
  font-weight: 700 !important;
}
.text-primary-200 {
  color: #bfd2fd !important;
}
.text-primary-500 {
  color: #2c63e5 !important;
}
.text-primary-600 {
  color: #264ca4;
}
.text-grey {
  color: #666666 !important;
}
.text-success-500 {
  color: #00c28b;
}
.text-alert-500 {
  color: #ff5757;
}
.text-alert-200{
  color: #dc354570;
}
.text-warning-600 {
  color: #d97f30;
}
.background-grey-200 {
  background-color: #c7c7c7 !important;
}
.poppins-16px-600 {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.poppins-11px-medium {
  font-size: 11px !important;
  font-weight: 600 !important;
}
.poppins-12px-medium {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.poppins-20px-semibold {
  font-size: 20px;
  font-weight: 600;
}
.source-sans-pro-reg {
  font-family: "Source Sans Pro", sans-serif;
}
.source-san-12px-reg {
  font-size: 12px;
  font-family: "Source Sans Pro", sans-serif;
}
.bg-none {
  background-color: white !important;
}
.border-radius-medium {
  border-radius: 8px;
}
/*************************************Theme Css End*******************************************/

/* Global Material Ui Changes */
.MuiIconButton-root:hover {
  background-color: inherit !important;
}
