.loginpage {
  background-color: #f5f8ff;
  min-height: 100vh;
}
.login-form-block {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
}
.form-footer {
  flex: 0;
  padding-right: 1em;
}
.form-block {
  flex: 1;
  padding: 0 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.container-left {
  text-align: center;
  margin-top: 4em;
}
.ImageContainer {
  padding: 10px;
  background-image: url("../Images/Rectangle 80@2x.png");
  height: 450px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
}
.ImageContainer img {
  width: 520px;
}
.forminput {
  font-size: 12px;
  color: #666666;
  font-weight: 500;
  width: 100%;
}
.form-lable-text {
  font-size: 12px;
  color: #1a1a1a;
  font-weight: 600;
  margin: 5px 0;
}
.login-sub-btn {
  background-color: #2c63e5 !important;
  color: white;
  width: 225px;
  text-align: center;
  margin-top: 2em;
  padding: 10px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  border-radius: 4px;
}

.login-sub-btn:hover{
  color: white;
}

.input-password {
  width: 225px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}
.form-input-username {
  width: 225px;
}
input:focus {
  outline: none !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.img-rounded {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 5px;
}
p {
  margin: 0;
}
.instruction-paragraph {
  font-size: 9px;
  line-height: 1.2;
  font-weight: 500;
}
.instruction-block {
  display: flex;
  justify-content: space-between;
  z-index: 1000;
}

.dotted-border {
  border: 1px dotted #264ca440;
  position: absolute;
  left: 0;
  right: 0;
  top: 18px;
  z-index: 0;
  margin-left: 4em;
}
.inst-method {
  z-index: 1000;
}

.small-btn-light {
  background-color: #f2f2f2 !important;
  border-radius: 4px !important;
  border: none !important;
  width: 87px !important;
  padding: 7px;
  margin: 10px 0;
  color: #1a1a1a !important;
}

.CustomFormGroup {
  margin: 8px 0;
}
